const {configuration}=require("./../../helpers/config")
const usersData=[
    {
        "id": 1,
        "username": process.env.NEXT_PUBLIC_USERNAME,
        "password": process.env.NEXT_PUBLIC_PASSWORD,
        "firstName": process.env.NEXT_PUBLIC_USER_FIRST_NAME,
        "lastName": process.env.NEXT_PUBLIC_USER_LAST_NAME
    }
]

export default usersData