/*
1.Components to apply the authorization  when page first loads
2.once authorized it will redirect to the homepage 
*/

import { BehaviorSubject } from "rxjs";
import getConfig from "next/config";
import Router from "next/router";

// import { fetchWrapper } from 'helpers';
import UsersDataJson from "./../../utils/userData/users";

const { publicRuntimeConfig } = getConfig();
// const baseUrl = `${publicRuntimeConfig.apiUrl}/users`;
const userSubject = new BehaviorSubject(
     process.browser && JSON.parse(JSON.stringify(localStorage.getItem("user")))//never comment this is production
);

export const userService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  login,
  logout,
  // getAll
};

// function login(username, password) {
//     return fetchWrapper.post(`${baseUrl}/authenticate`, { username, password })
//         .then(user => {
//             // publish user with basic auth credentials to subscribers and store in
//             // local storage to stay logged in between page refreshes
//             user.authdata = window.btoa(username + ':' + password);
//             userSubject.next(user);
//             localStorage.setItem('user', JSON.stringify(user));

//             return user;
//         });
// }

function login(username, password) {
  return new Promise(async function (resolve, reject) {
    const user = UsersDataJson.find(
      (item) => item.username == username && item.password == password
    );

    if (user) {
      user.authdata = window.btoa(username + ":" + password);
      userSubject.next(user);
      localStorage.setItem("user", JSON.stringify(user));

      resolve(user);
    } else {
      reject("Failed to login");
    }
  });

  // return fetchWrapper.post(`${baseUrl}/authenticate`, { username, password })
  //     .then(user => {
  //         // publish user with basic auth credentials to subscribers and store in
  //         // local storage to stay logged in between page refreshes
  //         user.authdata = window.btoa(username + ':' + password);
  //         userSubject.next(user);
  //         localStorage.setItem('user', JSON.stringify(user));

  //         return user;
  //     });
}

function logout() {
  // remove user from local storage, publish null to user subscribers and redirect to login page
  localStorage.removeItem("user");
  userSubject.next(null);
  Router.push("/login");
}

// function getAll() {
//     return fetchWrapper.get(baseUrl);
// }
